import { BasicLayout, UserLayout } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/dashboard/workplace',
    children: [
      // dashboard
      {
        path: '/dashboard',
        name: '看板',
        redirect: '/dashboard/workplace',
        component: RouteView,
        meta: { title: 'menu.dashboard', keepAlive: true, icon: bxAnaalyse, permission: ['dashboard'] },
        children: [
          {
            path: '/dashboard/analysis/:pageNo([1-9]\\d*)?',
            name: '业务报表',
            component: () => import('@/views/dashboard/Analysis'),
            meta: { title: 'menu.dashboard.analysis', keepAlive: false, 'hideInMenu': true, permission: ['dashboard'] }
          },
          // // 外部链接
          // {
          //   path: 'https://www.baidu.com/',
          //   name: 'Monitor',
          //   meta: { title: 'menu.dashboard.monitor', target: '_blank' }
          // },
          {
            path: '/dashboard/workplace',
            name: '工作台',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: 'menu.dashboard.workplace', keepAlive: true, permission: ['dashboard'] }
          }
        ]
      },
      // {
      //   path: '/list',
      //   name: 'list',
      //   component: RouteView,
      //   redirect: '/list/table-list',
      //   meta: { title: '列表页', icon: 'table', permission: [ 'table' ] },
      //   children: [
      //     {
      //       path: '/list/table-list/:pageNo([1-9]\\d*)?',
      //       name: 'TableListWrapper',
      //       hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
      //       component: () => import('@/views/list/TableList'),
      //       meta: { title: '查询表格', keepAlive: true, permission: [ 'table' ] }
      //     },
      //     {
      //       path: '/list/basic-list',
      //       name: 'BasicList',
      //       component: () => import('@/views/list/BasicList'),
      //       meta: { title: '标准列表', keepAlive: true, permission: [ 'table' ] }
      //     },
      //     {
      //       path: '/list/card',
      //       name: 'CardList',
      //       component: () => import('@/views/list/CardList'),
      //       meta: { title: '卡片列表', keepAlive: true, permission: [ 'table' ] }
      //     },
      //     {
      //       path: '/list/search',
      //       name: 'SearchList',
      //       component: () => import('@/views/list/search/SearchLayout'),
      //       redirect: '/list/search/article',
      //       meta: { title: '搜索列表', keepAlive: true, permission: [ 'table' ] },
      //       children: [
      //         {
      //           path: '/list/search/article',
      //           name: 'SearchArticles',
      //           component: () => import('../views/list/search/Article'),
      //           meta: { title: '搜索列表（文章）', permission: [ 'table' ] }
      //         },
      //         {
      //           path: '/list/search/project',
      //           name: 'SearchProjects',
      //           component: () => import('../views/list/search/Projects'),
      //           meta: { title: '搜索列表（项目）', permission: [ 'table' ] }
      //         },
      //         {
      //           path: '/list/search/application',
      //           name: 'SearchApplications',
      //           component: () => import('../views/list/search/Applications'),
      //           meta: { title: '搜索列表（应用）', permission: [ 'table' ] }
      //         }
      //       ]
      //     }
      //   ]
      // },
      {
        'path': '/activity',
        'name': 'activity',
        'component': RouteView,
        'redirect': '/activity/ActivityList',
        'meta': {
          'title': 'module.activity',
          'icon': 'table',
          'permission': [
            'table'
          ]
        },
        'children': [
          {
            'path': '/activity/ActivityList',
            'name': 'ActivityList',
            'component': () => import('@/views/activity/ActivityList'),
            'meta': {
              'title': 'page.activityManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          }
        ]
      },
      {
        'path': '/order',
        'name': 'order',
        'component': RouteView,
        'redirect': '/order/SaleOrderList',
        'meta': {
          'title': 'module.order',
          'icon': 'table',
          'permission': [
            'table'
          ]
        },
        'children': [
          {
            'path': '/order/SaleOrderList',
            'name': 'SaleOrderList',
            'component': () => import('@/views/order/SaleOrderList'),
            'meta': {
              'title': 'page.saleOrderManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/order/ServiceOrderList',
            'name': 'ServiceOrderList',
            'component': () => import('@/views/order/ServiceOrderList'),
            'meta': {
              'title': 'page.serviceOrderManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          }
        ]
      },

      {
        'path': '/pay',
        'name': 'pay',
        'component': RouteView,
        'redirect': '/pay/TransferFundList',
        'meta': {
          'title': 'module.pay',
          'icon': 'table',
          'permission': [
            'table'
          ]
        },
        'children': [
          {
            'path': '/pay/WithdrawBillList',
            'name': 'WithdrawBillList',
            'component': () => import('@/views/pay/WithdrawBillList'),
            'meta': {
              'title': 'page.withdrawBillManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
        ]
      },
      {
        'path': '/shipping',
        'name': 'shipping',
        'component': RouteView,
        'redirect': '/shipping/ShippingBillList',
        'meta': {
          'title': 'module.shipping',
          'icon': 'table',
          'permission': [
            'table'
          ]
        },
        'children': [
          {
            'path': '/shipping/DeliveryCompanyList',
            'name': 'DeliveryCompanyList',
            'component': () => import('@/views/shipping/DeliveryCompanyList'),
            'meta': {
              'title': 'page.deliveryCompanyManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
        ]
      },
      {
        'path': '/member',
        'name': 'member',
        'component': RouteView,
        'redirect': '/member/MemberList',
        'meta': {
          'title': 'module.member',
          'icon': 'table',
          'permission': [
            'table'
          ]
        },
        'children': [
          {
            'path': '/member/MemberList',
            'name': 'MemberList',
            'component': () => import('@/views/member/MemberList'),
            'meta': {
              'title': 'page.memberManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },  
        ]
      },
      {
        'path': '/cms',
        'name': 'cms',
        'component': RouteView,
        'redirect': '/cms/HomeTemplateList',
        'meta': {
          'title': 'module.cms',
          'icon': 'table',
          'permission': [
            'table'
          ]
        },
        'children': [
          {
            'path': '/cms/HomeTemplateList',
            'name': 'HomeTemplateList',
            'component': () => import('@/views/cms/HomeTemplateList'),
            'meta': {
              'title': 'page.homeTemplateManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/cms/HomeTemplateDisplayList',
            'name': 'HomeTemplateDisplayList',
            'component': () => import('@/views/cms/HomeTemplateDisplayList'),
            'meta': {
              'title': 'page.homeTemplateDisplayManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/cms/HomeTemplateEdit',
            'name': 'HomeTemplateEdit',
            'component': () => import('@/views/cms/HomeTemplateEdit'),
            'meta': {
              'hideInMenu': true,
              'title': 'page.homeTemplateManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          }
        ]
      },
      {
        'path': '/marketing',
        'name': 'marketing',
        'component': RouteView,
        'redirect': '/marketing/CouponList',
        'meta': {
          'title': 'module.marketing',
          'icon': 'table',
          'permission': [
            'table'
          ]
        },
        'children': [
          {
            'path': '/marketing/CouponTypeList',
            'name': 'CouponTypeList',
            'component': () => import('@/views/marketing/CouponTypeList'),
            'meta': {
              'title': 'page.couponTypeManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/marketing/CouponList',
            'name': 'CouponList',
            'component': () => import('@/views/marketing/CouponList'),
            'meta': {
              'title': 'page.couponManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/marketing/CampaignList',
            'name': 'CampaignList',
            'component': () => import('@/views/marketing/CampaignList'),
            'meta': {
              'title': 'page.campaignManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/marketing/CouponEdit',
            'name': 'CouponEdit',
            'component': () => import('@/views/marketing/CouponEdit'),
            'meta': {
              'hideInMenu': true,
              'title': 'page.couponManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/marketing/CampaignEdit',
            'name': 'CampaignEdit',
            'component': () => import('@/views/marketing/CampaignEdit'),
            'meta': {
              'hideInMenu': true,
              'title': 'page.campaignManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          }
        ]
      },
      // {
      //   'path': '/stock',
      //   'name': 'stock',
      //   'component': RouteView,
      //   'redirect': '/stock/SkuOnOffList',
      //   'meta': {
      //     'title': 'module.stock',
      //     'icon': 'table',
      //     'permission': [
      //       'table'
      //     ]
      //   },
      //   'children': [
      //     {
      //       'path': '/stock/SkuOnOffList',
      //       'name': 'SkuOnOffList',
      //       'component': () => import('@/views/stock/SkuOnOffList'),
      //       'meta': {
      //         'title': 'page.skuOnOffManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/stock/SkuOnOffHistoryList',
      //       'name': 'SkuOnOffHistoryList',
      //       'component': () => import('@/views/stock/SkuOnOffHistoryList'),
      //       'meta': {
      //         'title': 'page.skuOnOffHistoryManage',
      //         'hideInMenu': true,
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/stock/SkuStockList',
      //       'name': 'SkuStockList',
      //       'component': () => import('@/views/stock/SkuStockList'),
      //       'meta': {
      //         'title': 'page.skuStockManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/stock/SkuStockWarehouseHistoryList',
      //       'name': 'SkuStockWarehouseHistoryList',
      //       'component': () => import('@/views/stock/SkuStockWarehouseHistoryList'),
      //       'meta': {
      //         'title': 'page.skuStockWarehouseHistoryManage',
      //         'hideInMenu': true,

      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/stock/SkuStockMarketplaceHistoryList',
      //       'name': 'SkuStockMarketplaceHistoryList',
      //       'component': () => import('@/views/stock/SkuStockMarketplaceHistoryList'),
      //       'meta': {
      //         'title': 'page.skuStockMarketplaceHistoryManage',
      //         'hideInMenu': true,

      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     }
      //   ]
      // },
      // {
      //   'path': '/pricing',
      //   'name': 'pricing',
      //   'component': RouteView,
      //   'redirect': '/pricing/SkuPriceList',
      //   'meta': {
      //     'title': 'module.pricing',
      //     'icon': 'table',
      //     'permission': [
      //       'table'
      //     ]
      //   },
      //   'children': [
      //     {
      //       'path': '/pricing/SkuPriceList',
      //       'name': 'SkuPriceList',
      //       'component': () => import('@/views/pricing/SkuPriceList'),
      //       'meta': {
      //         'title': 'page.skuPriceManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/pricing/SkuPriceHistoryList',
      //       'name': 'SkuPriceHistoryList',
      //       'component': () => import('@/views/pricing/SkuPriceHistoryList'),
      //       'meta': {
      //         'title': 'page.skuPriceHistoryManage',
      //         'hideInMenu': true,

      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     }
      //   ]
      // },
      // {
      //   'path': '/goodsDisplay',
      //   'name': 'goodsDisplay',
      //   'component': RouteView,
      //   'redirect': '/goodsDisplay/SpuDisplayList',
      //   'meta': {
      //     'title': 'module.goodsDisplay',
      //     'icon': 'table',
      //     'permission': [
      //       'table'
      //     ]
      //   },
      //   'children': [
      //     {
      //       'path': '/goodsDisplay/SpuDisplayList',
      //       'name': 'SpuDisplayList',
      //       'component': () => import('@/views/goodsDisplay/SpuDisplayList'),
      //       'meta': {
      //         'title': 'page.spuDisplayList',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsDisplay/SkuDisplayList',
      //       'name': 'SkuDisplayList',
      //       'component': () => import('@/views/goodsDisplay/SkuDisplayList'),
      //       'meta': {
      //         'title': 'page.skuDisplayList',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsDisplay/DisplayCategoryList',
      //       'name': 'DisplayCategoryList',
      //       'component': () => import('@/views/goodsDisplay/DisplayCategoryList'),
      //       'meta': {
      //         'title': 'page.displayCategoryManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsDisplay/DisplayTemplateList',
      //       'name': 'DisplayTemplateList',
      //       'component': () => import('@/views/goodsDisplay/DisplayTemplateList'),
      //       'meta': {
      //         'title': 'page.displayTemplateManage',
      //         'keepAlive': true,
      //         'hideInMenu': true,

      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     }, {
      //       'path': '/goods/SkuDisplayEdit',
      //       'name': 'SkuDisplayEdit',
      //       'component': () => import('@/views/goodsDisplay/SkuDisplayEdit'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SkuDisplayEdit',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SkuDisplayDetail',
      //       'name': 'SkuDisplayDetail',
      //       'component': () => import('@/views/goodsDisplay/SkuDisplayDetail'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SkuDisplayDetail',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SpuDisplayEdit',
      //       'name': 'SpuDisplayEdit',
      //       'component': () => import('@/views/goodsDisplay/SpuDisplayEdit'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SpuDisplayEdit',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SpuDisplayDetail',
      //       'name': 'SpuDisplayDetail',
      //       'component': () => import('@/views/goodsDisplay/SkuDisplayDetail'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SpuDisplayDetail',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     }
      //   ]
      // },
      // {
      //   'path': '/goods',
      //   'name': 'goods',
      //   'component': RouteView,
      //   'redirect': '/goods/SpuList',
      //   'meta': {
      //     'title': 'module.goods',
      //     'icon': 'table',
      //     'permission': [
      //       'table'
      //     ]
      //   },
      //   'children': [
      //     {
      //       'path': '/goods/SpuList',
      //       'name': 'SpuList',
      //       'component': () => import('@/views/goods/SpuList'),
      //       'meta': {
      //         'title': 'page.spuManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SkuList',
      //       'name': 'SkuList',
      //       'component': () => import('@/views/goods/SkuList'),
      //       'meta': {
      //         'title': 'page.skuManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SkuServiceList',
      //       'name': 'SkuServiceList',
      //       'component': () => import('@/views/goods/SkuServiceList'),
      //       'meta': {
      //         'title': 'page.skuServiceManage',
      //         'keepAlive': true,
      //         'hideInMenu': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },

      //     {
      //       'path': '/goods/SkuReviewList',
      //       'name': 'SkuReviewList',
      //       'component': () => import('@/views/goods/SkuReviewList'),
      //       'meta': {
      //         'title': 'page.skuReviewManage',
      //         'keepAlive': true,
      //         'hideInMenu': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SpuEdit',
      //       'name': 'SpuEdit',
      //       'component': () => import('@/views/goods/SpuEdit'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SpuEdit',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SpuDetail',
      //       'name': 'SpuDetail',
      //       'component': () => import('@/views/goods/SpuDetail'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SpuDetail',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SkuEdit',
      //       'name': 'SkuEdit',
      //       'component': () => import('@/views/goods/SkuEdit'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SkuEdit',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goods/SkuDetail',
      //       'name': 'SkuDetail',
      //       'component': () => import('@/views/goods/SkuDetail'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SkuDetail',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     }
      //   ]
      // },
      // {
      //   'path': '/goodsSetting',
      //   'name': 'goodsSetting',
      //   'component': RouteView,
      //   'redirect': '/goodsSetting/UnitList',
      //   'meta': {
      //     'title': 'module.goodsSetting',
      //     'icon': 'table',
      //     'permission': [
      //       'table'
      //     ]
      //   },
      //   'children': [
      //     {
      //       'path': '/goodsSetting/UnitList',
      //       'name': 'UnitList',
      //       'component': () => import('@/views/goodsSetting/UnitList'),
      //       'meta': {
      //         'title': 'page.unitManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsSetting/BrandList',
      //       'name': 'BrandList',
      //       'component': () => import('@/views/goodsSetting/BrandList'),
      //       'meta': {
      //         'title': 'page.brandManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/system/AfterSaleServiceList',
      //       'name': 'AfterSaleServiceList',
      //       'component': () => import('@/views/system/AfterSaleServiceList'),
      //       'meta': {
      //         'title': 'page.afterSaleServiceManage',
      //         'keepAlive': true,
      //         'hideInMenu': true,

      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsSetting/SkuAttrList',
      //       'name': 'SkuAttrList',
      //       'component': () => import('@/views/goodsSetting/SkuAttrList'),
      //       'meta': {
      //         'title': 'page.skuAttrManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsSetting/SkuAttrEdit',
      //       'name': 'SkuAttrEdit',
      //       'component': () => import('@/views/goodsSetting/SkuAttrEdit'),
      //       'meta': {
      //         'hideInMenu': true,
      //         'title': 'page.SkuAttrEdit',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsSetting/SkuAttrTemplateList',
      //       'name': 'SkuAttrTemplateList',
      //       'component': () => import('@/views/goodsSetting/SkuAttrTemplateList'),
      //       'meta': {
      //         'title': 'page.skuAttrTemplateManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsSetting/CategoryList',
      //       'name': 'CategoryList',
      //       'component': () => import('@/views/goodsSetting/CategoryList'),
      //       'meta': {
      //         'title': 'page.categoryManage',
      //         'keepAlive': true,
      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsSetting/SpuTagList',
      //       'name': 'SpuTagList',
      //       'component': () => import('@/views/goodsSetting/SpuTagList'),
      //       'meta': {
      //         'title': 'page.spuTagManage',
      //         'keepAlive': true,
      //         'hideInMenu': true,

      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     },
      //     {
      //       'path': '/goodsSetting/SkuTagList',
      //       'name': 'SkuTagList',
      //       'component': () => import('@/views/goodsSetting/SkuTagList'),
      //       'meta': {
      //         'title': 'page.skuTagManage',
      //         'keepAlive': true,
      //         'hideInMenu': true,

      //         'permission': [
      //           'form'
      //         ]
      //       }
      //     }
      //   ]
      // },
      {
        'path': '/system',
        'name': 'system',
        'component': RouteView,
        'redirect': '/system/MarketplaceList',
        'meta': {
          'hideInMenu': true,
          'title': 'module.system',
          'icon': 'table',
          'permission': [
            'table'
          ]
        },
        'children': [
          {
            'path': '/system/MarketplaceList',
            'name': 'MarketplaceList',
            'component': () => import('@/views/system/MarketplaceList'),
            'meta': {
              'title': 'page.marketplaceManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/system/SiteList',
            'name': 'SiteList',
            'component': () => import('@/views/system/SiteList'),
            'meta': {
              'title': 'page.siteManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/system/AppList',
            'name': 'AppList',
            'component': () => import('@/views/system/AppList'),
            'meta': {
              'title': 'page.appManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/system/AppVersionList',
            'name': 'AppVersionList',
            'component': () => import('@/views/system/AppVersionList'),
            'meta': {
              'title': 'page.appVersionManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/system/WarehouseList',
            'name': 'WarehouseList',
            'component': () => import('@/views/system/WarehouseList'),
            'meta': {
              'title': 'page.warehouseManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/system/SupplierCompanyList',
            'name': 'SupplierCompanyList',
            'component': () => import('@/views/system/SupplierCompanyList'),
            'meta': {
              'title': 'page.supplierCompanyManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          },
          {
            'path': '/system/SupplierWarehouseList',
            'name': 'SupplierWarehouseList',
            'component': () => import('@/views/system/SupplierWarehouseList'),
            'meta': {
              'title': 'page.supplierWarehouseManage',
              'keepAlive': true,
              'permission': [
                'form'
              ]
            }
          }
        ]
      }
      // other
      // {
      //   path: '/图标选择器',
      //   name: 'otherPage',
      //   component: PageView,
      //   meta: { title: '图标选择器', icon: 'slack', permission: ['dashboard'] },
      //   redirect: '/other/icon-selector',
      //   children: [
      //     {
      //       path: '/other/icon-selector',
      //       name: 'TestIconSelect',
      //       component: () => import('@/views/other/IconSelectorView'),
      //       meta: { title: 'IconSelector', icon: 'tool', keepAlive: true, permission: ['dashboard'] }
      //     }]
      // },
      // other
    ]
  },
  {
    path: '*',
    redirect:
      '/404',
    hidden:
      true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }

]
