<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'

export default {
  data () {
    return {
    }
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))
      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
}
</script>
<style lang="less">
.ant-table-content  {
  p {
    margin-bottom: 0;
  }
  .ant-table-thead > tr > th {
    box-sizing: border-box;
    min-width: calc(5.2em);
    padding: 1em;
    
    &.ant-table-selection-column {
      min-width: 50px;
    }
  }
}

.word-break {
  white-space:normal; 
  word-break:break-all;
}
.word-wrap {
  white-space:normal; 
  word-wrap: break-word; 
}

</style>
