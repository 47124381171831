import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import {
  ACCESS_TOKEN,
  APP_LANGUAGE,
  OPERATOR_CODE,
  OPERATOR_ID,
  OPERATOR_NAME,
  OPERATOR_TOKEN,
  USER_INFO
} from '@/store/mutation-types'

// 创建 axios 实例
let baseUrl = window.location.origin;
if (!baseUrl.includes('ops-dev')) {
  baseUrl = baseUrl.replace('grabie.cn', 'grabie.cc')
}

const request = axios.create({
  // API 请求的默认前缀
  baseURL: baseUrl,
  timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        console.log('退出登录')
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Access-Token'] = token
  }
  // const uid = storage.get(OPERATOR_CODE)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  const userInfo = storage.get(USER_INFO)
  if (userInfo) {
    config.headers[OPERATOR_CODE] = userInfo[OPERATOR_CODE]
    config.headers[OPERATOR_TOKEN] = userInfo[OPERATOR_TOKEN]
    config.headers[OPERATOR_NAME] = encodeURIComponent(userInfo[OPERATOR_NAME])
    config.headers[OPERATOR_ID] = userInfo[OPERATOR_ID]
  }
  console.debug('request intercept', config)
  config.headers['language'] = storage.get(APP_LANGUAGE)
  // POST传参序列化
  // if (config.method === 'post') {
  //   config.data = JSON.stringify(config.params)
  // }
  if (config.data && (config.data.page || config.data.pageNo || config.data.pageSize)) {
    config.data.pageParam = {
      pageNo: config.data.page || config.data.pageNo,
      pageSize: config.data.pageSize
    }
  }
  if (config.data) {
    config.data.loginAs = 'ops'
  }
  // console.log('config:', config)
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
