import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function checkExists (params) {
  let url = baseUrl + '/system/checkExists'
  if (params.url) {
    url = params.url
  }
  return request({
    url: url,
    method: 'post',
    data: params
  }).then(res => {
    return res.data
  })
}
