import storage from 'store'
import { getInfo, login, logout } from '@/api/login'
import {
  ACCESS_TOKEN,
  OPERATOR_CODE,
  OPERATOR_ID,
  OPERATOR_NAME,
  OPERATOR_TOKEN,
  USER_INFO
} from '@/store/mutation-types'
import { welcome } from '@/utils/util'
import md5 from 'md5'
import moment from 'moment'

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      if (userInfo.type === 'sys') {
        return new Promise((resolve, reject) => {
          console.debug('Login', userInfo)
          login(userInfo).then(response => {
            const userInfo = response.result
            // userInfo = {}
            userInfo[OPERATOR_CODE] = userInfo.loginName
            userInfo[OPERATOR_TOKEN] = userInfo.operatorToken
            userInfo[OPERATOR_NAME] = userInfo.userName
            userInfo[OPERATOR_ID] = userInfo.operatorId
            storage.set(USER_INFO, userInfo)
            const result = { token: 1234 }
            storage.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            resolve()
            //
            // storage.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            // commit('SET_TOKEN', result.token)
            // resolve()
          }).catch(error => {
            reject(error)
          })
          console.debug('Login@user.js', userInfo)
          // login(userInfo).then(response => {
          //   const result = response.result
          //   storage.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
          //   commit('SET_TOKEN', result.token)
          //   resolve()
          // }).catch(error => {
          //   reject(error)
          // })
        })
      } else {
        return new Promise((resolve, reject) => {
          let validUser = ['lx', 'jxh', 'zjl', 'sl', 'lxy', 'lf', 'jc', 'wq', 'jpx', 'lhx', 'lyj', 'dyh', 'hzp', 'zjx', 'wfy', 'jpx']
          if (validUser.indexOf(userInfo.loginName) != -1 && md5(userInfo.loginName + moment().format('YYYYMMDDHHmm')) == userInfo.password) {
            userInfo[OPERATOR_CODE] = userInfo.loginName
            userInfo[OPERATOR_TOKEN] = userInfo.loginName
            userInfo[OPERATOR_NAME] = userInfo.loginName
            userInfo[OPERATOR_ID] = 10000000 + validUser.indexOf(userInfo.loginName)
            storage.set(USER_INFO, userInfo)
            const result = { token: 10000000 + validUser.indexOf(userInfo.loginName) }
            storage.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            resolve()
          } else {
            reject(new Error('用户名密码错误'))
          }
        })
      }
    },
// 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.result

          if (result.role && result.role.permissions.length > 0) {
            const role = result.role
            role.permissions = result.role.permissions
            role.permissions.map(per => {
              if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                const action = per.actionEntitySet.map(action => {
                  return action.action
                })
                per.actionList = action
              }
            })
            role.permissionList = role.permissions.map(permission => {
              return permission.permissionId
            })
            commit('SET_ROLES', result.role)
            commit('SET_INFO', result)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }

          commit('SET_NAME', { name: result.name, welcome: welcome() })
          commit('SET_AVATAR', result.avatar)

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
        })
      })
    }
  }
}

export default user
