import Vue from 'vue'
import { checkExists } from '@/api/system/validators'

const newRules = {
  'required': {
    required: true,
    message: `请输入 [${name}]`
  },
  'len': {
    len: 4
  },
  spuNo: [
    {
      len: 8,
      message: `请输入8位 [${name}]`
    },
    {
      message: '',
      validator (rule, value, callback) {
        if (value.length === 8) {
          setTimeout(() => {
            rule.message = `[${name}] 已存在`
            callback(false)
          })
        } else {
          callback(true)
        }
      }
    }
  ],
  maxValue: {
    validator (rule, value, callback) {
      if (value > rule.maxValue) {
        callback(false)
      } else {
        callback()
      }
    }
  },
  checkExists:
    {
      message: '',
      validator (rule, value, callback) {
        console.debug('checkExists', rule, value)
        let checkValue = value
        if (rule.checkFieldBaseValue) {
          checkValue = rule.checkFieldBaseValue + value
        }
        let param = Object.assign({ checkValue: checkValue }, rule)
        checkExists(param).then((result) => {
          console.debug('validator->checkExists', param, result)
          if (!result) {
            rule.message = `[${value}] 已存在`
            callback(false)
          } else {
            callback()
          }
        }).catch((res) => {
          rule.message = '验证失败'
          callback(false)
        })
      }
    }
}
Vue.prototype.$rules = (keys = [], name) => {
  const result = []
  keys.map(item => {
    let type = item.type
    if (!type) {
      result.push(item)
    } else {
      let rule = Object.assign({}, newRules[item.type])
      if (typeof item === 'object') {
        Object.assign(rule, item)
      }
      result.push(rule)
    }
  })
  console.log('创建验证规则, name:' + name + ', rules', result)
  return result
}
