<template>
  <div class="menu-search-container">
    <el-select
      filterable
      remote
      :clearable="true"
      v-model="value"
      :filter-method="filterMethod"
      placeholder="搜索 支持 全音、首拼"
      icon="search"
      size="mini"
      popper-class="menu-search-dropdown"
      class="fs-select"
      @change="onChange"
      @clear="onClear"
      ref="select"
    >
      <el-option-group
        v-for="(group, index) in searchList"
        :key="index"
        :label="group.label"
        :class="[group.history ? 'group-history' : '']"
      >
        <div class="menu-search-history-list" v-if="group.history">
          <el-tag
            type="primary"
            size="mini"
            v-for="(item, index) in group.options"
            :key="index"
            @click.native="onPageJump(item.value)"
          >
            {{ item.label }}
          </el-tag>
        </div>
        <template v-else>
          <el-option v-for="(item, index) in group.options" :key="index" :label="item.label" :value="item.value">
          </el-option>
        </template>
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import PinyinMatch from 'pinyin-match'
window.PinyinMatch = PinyinMatch
export default {
  props: {
    menu: {
      type: Object
    }
  },
  data () {
    return {
      value: '',
      searchValue: '',
      searchValueLock: false,
      list: [],
      flatMenuList: [],
      historyList: JSON.parse(localStorage.getItem('ops_menu_history')) || []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initData()
      this.flatDataHandle()
    })
  },
  methods: {
    initData () {
      try {
        this.list = this.menu.$router.options.routes
          .filter(item => !['/user', '/404'].includes(item.path))
          .map(item => {
            let { path, name: label, name = '', children = [], meta = {} } = item
            if (meta.title) {
              label = this.$t(meta.title)
            }
            const group = {
              label,
              name,
              title: meta.title || '',
              value: path
            }
            group.options = children
              .filter(o => o.hidden !== true)
              .map(o => {
                let { path, name: label, meta = {}, name = '' } = o
                if (meta.title) {
                  label = this.$t(meta.title)
                }
                return {
                  value: path,
                  label,
                  title: meta.title || '',
                  name
                }
              })
            return group
          })
      } catch (error) {
        console.log(error)
      }
    },
    filterMethod (query) {
      if (!this.searchValueLock) {
        this.searchValue = query
      }
    },
    onClear () {
      this.searchValue = ''
    },
    onChange (val) {
      val && this.$router.push({ path: val })

      this.searchValueLock = true
      setTimeout(() => {
        this.searchValueLock = false
      })
    },
    flatDataHandle () {
      const { list = [] } = this
      this.flatMenuList = list.reduce((a, b) => {
        return a.concat(...b.options)
      }, [])
    },
    updateHistoryData (newList = []) {
      const { list = [], historyList = [], flatMenuList } = this
      if (list && list.length && historyList && historyList.length) {
        const options = historyList
          .slice(0, 10)
          .map(key => {
            return flatMenuList.filter(o => o.value === key)[0]
          })
          .filter(item => item && item.label)
        const historyData = {
          label: '历史记录',
          history: true,
          options
        }
        newList.unshift(historyData)
      }
      return newList
    },
    onPageJump (val) {
      this.$refs.select.visible = false
      val && this.$router.push({ path: val })
      this.value = val
    }
  },
  computed: {
    searchList () {
      const { searchValue = '', list = [] } = this
      let newList = [...list]
      try {
        if (searchValue) {
          newList = newList
            .map(item => {
              const { label = '', options = [] } = item
              let newOption = []
              if (PinyinMatch.match(label, searchValue)) {
                newOption = options
              } else {
                newOption = options.filter(
                  cItem =>
                    PinyinMatch.match(cItem.label, searchValue) ||
                    PinyinMatch.match(cItem.value, searchValue) ||
                    PinyinMatch.match(cItem.title, searchValue) ||
                    PinyinMatch.match(cItem.name, searchValue)
                )
              }
              return {
                label,
                options: newOption
              }
            })
            .filter(item => item.options.length)
        }
      } catch (error) {
        console.log(error)
      }

      newList = this.updateHistoryData(newList)
      return newList
    }
  },
  watch: {
    'menu.$route.path' (newVal) {
      if (newVal !== this.value) {
        this.value = ''
      }
      let historyList = JSON.parse(localStorage.getItem('ops_menu_history')) || []
      historyList.unshift(newVal)
      historyList = Array.from(new Set(historyList))
      this.historyList = historyList
      localStorage.setItem('ops_menu_history', JSON.stringify(historyList))
    }
  }
}
</script>

<style lang="less">
.menu-search-container {
  border-top: 1px solid #c0d6d9;
  border-radius: 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  .fs-select {
    width: 90%;
    input {
      background: none;
      color: #fff;
      padding: 0 10px;
    }
  }
}
.menu-search-container + ul {
  border-radius: 0;
}
.menu-search-dropdown {
  width: 260px;
  .el-select-dropdown__wrap {
    max-height: 500px;
  }
  .group-history {
    display: block !important;
    .el-select-group__title {
      display: block !important;
    }
  }
  .el-select-group__title {
    padding-left: 10px;
  }
  .el-select-group__wrap:not(:last-of-type)::after {
    display: none;
  }
  .el-select-group__wrap:not(:last-of-type) {
    padding-bottom: 24px;
    margin: 0;
    padding: 0;
  }
}
.menu-search-history-list {
  padding: 0 10px;
  .el-tag {
    cursor: pointer;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 0;
    &:hover {
      background-color: #409eff;
      color: #fff;
    }
  }
}
</style>
