const getDataValue = function(that, arr=[]) {
  return arr.reduce((pre, val) => {
    pre = (pre || that)[val] || {}
    return pre
  }, null)
}
const setDataValue = function (that, arr=[], value) {
  let f = arr.pop()
  let obj = arr.reduce((pre, val) => {
      pre = (pre || that)[val] || {}
      return pre
    }, null)
  obj[f] = value
}

module.exports = {
  getDataValue, setDataValue
}