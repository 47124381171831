import { mapState } from 'vuex'
import moment from 'moment'

const codeList = {
  /**
   * 货币符号
   */
  currencySymbol: {
    'USA': '$',
    'U002': 'C$',
    'U003': 'Mex$',
    'CN': '￥'
  },
  saleOrderStatus: {
    '已创建': '待支付',
    '待支付': '待支付',
    '拼团中': '拼团中',
    '待发货': '待发货',
    '待收货': '待团长收货',
    '待分货': '待团员收货',
    '已完成': '已完成',
    '取消中': '已取消[取消中]',
    '已取消': '已取消',
    '退款中': '已取消[退款中]',
    '已退款': '已取消[已退款]'
  },

  billStatus: {
    '已创建': '待确认',
    '待处理': '待处理',
    '已处理': '已处理',
    '已取消': '已取消'
  },

  orderStatus: {
    '1': '待发货'
  },
  activityStatus: {
    '20': '开团中',
    '1': '未开团',
    '5': '待审核',
    '9': '已拒绝',
    '30': '已结束',
    '10': '开团中',
    '50': '团长暂停',
    '60': '管理员暂停',
    '100': '已删除'
  },
  isSlaveOrder: {
    true: '团员订单',
    false: '团长订单'
  },
  goodsStatus: {
    '10': '在售',
    '5': '无货',
    '3': '已删除',
    '-1': '已下架'
  },
  payModel: {
    '20': '面对面支付'
  },
  roleStatus: {
    '1': '正常',
    '2': '禁用'
  },
  userStatus: {
    '1': '正常',
    '2': '禁用'
  },
  payMode: {
    '20': '面对面支付'
  },
  logisticsModel: {
    '20': '自提',
    '10': '快递'
  },
  dimensionModel: {
    '0': '无多规格',
    '20': '无多规格',
    '10': '多规格'
  },
  transferStatus: {
    'Created': '有效',
    'Processing': '处理中',
    'Complete': '转账成功',
    'Fail': '转账失败',
    'Cancel': '已取消'
  },
  transferMethodType: {
    '1': '微信转账',
    '2': '银行转账'
  },
  languageName:
  {
    'en-us': '英语(美国)',
    'es-us': '西班牙语(美国)',
    'es-mx': '西班牙语(墨西哥)',
    'pt-br': '葡萄牙语(巴西)'
  },
  enableStatus: {
    '1': '有效',
    '0': '无效'
  },
  status: [
    { label: '有效', value: '1' },
    { label: '无效', value: '0' }
  ],
  country: [{ label: '美国', value: 'USA' }, { label: '墨西哥', value: 'Maxico' }, { label: '巴西', value: 'Brazil' }],
  timeLimitType: [
    { label: '固定期间', value: 'fixedPeriod' },
    { label: '领取后生效', value: 'fromStart' }
    //   , {
    //   label: '固定截止时间',
    //   value: 'fixedEnd'
    // }
  ],
  language: [
    { label: '英语(美国)', value: 'en-us' }
    // { label: '西班牙语(美国)', value: 'es-us' },
    // { label: '西班牙语(墨西哥)', value: 'es-mx' },
    // { label: '葡萄牙语(巴西)', value: 'pt-br' }
  ],
  isForceUpdate: [{ label: '是', value: true }, { label: '否', value: false }],

  onOffStatus: [
    {
      label: '上架',
      value: 'on'
    },
    {
      label: '下架',
      value: 'off'
    }

  ],
  spuStatus: [
    { label: '草拟中', value: '草拟中' },
    { label: '有效', value: '有效' },
    { label: '无效', value: '无效' }
  ],
  onlineStatus: [
    { label: '未上线', value: '未上线' },
    { label: '上线中', value: '上线中' },
    { label: '已下线', value: '已下线' }
  ],
  homeTemplateStatus: [
    { label: '草拟中', value: '草拟中' },
    { label: '有效', value: '有效' },
    { label: '无效', value: '无效' }
  ],
  serviceType: [
    { label: '包邮', value: '包邮' },
    { label: '包退', value: '包退' },
    { label: '礼品包装', value: '礼品包装' },
    { label: '包换', value: '包换' }
  ],
  skuStatus: [
    { label: '草拟中', value: '草拟中' },
    { label: '有效', value: '有效' },
    { label: '无效', value: '无效' }
  ],
  couponStatus: [
    { label: '草拟中', value: '草拟中' },
    { label: '已发布', value: '已发布' },
    { label: '无效', value: '无效' }
  ],
  couponUseStatus: [
    { label: '已过期', value: '已过期' },
    { label: '已使用', value: '已使用' },
    { label: '未领取', value: '未领取' },
    { label: '未使用', value: '未使用' }
  ],
  isFirstSku: [{ label: '是', value: '1' },
  { label: '否', value: '0' }],
  marketingConditionTypeValue: {
    '1': '全品',
    '10': '品类',
    '20': '品牌',
    '30': '品牌SPU',
    '40': '品牌SKU',
    '50': '品类SPU',
    '60': '品类SKU',
    '70': '品类品牌SPU',
    '80': '品类品牌SKU',
    '90': 'SPU',
    '100': 'SKU',
    '200': '会员等级'

  },
  marketingDiscountTypeValue: {
    // '10': '运费金额',
    // '20': '运费比例',
    '30': '商品价格金额'
    // '40': '商品价格比例'
  },
  marketingDiscountType: [
    // { label: '运费金额', value: '10' },
    // { label: '运费比例', value: '20' },
    { label: '商品价格金额', value: '30' }
    // { label: '商品价格比例', value: '40' }
  ],
  marketingConditionType: [{ label: '全品', value: '1' },
  { label: '品类', value: '10' },
  { label: '品牌', value: '20' },
  { label: '品牌SPU', value: '30' },
  { label: '品牌SKU', value: '40' },
  { label: '品类SPU', value: '50' },
  { label: '品类SKU', value: '60' },
  { label: '品类品牌SPU', value: '70' },
  { label: '品类品牌SKU', value: '80' },
  { label: 'SPU', value: '90' },
  { label: 'SKU', value: '100' },
  { label: '会员等级', value: '200' }
  ],
  couponMarketingConditionType: [{ label: '全品', value: '1' }

  ],
  campaignMarketingConditionType: [
    { label: 'SPU', value: '90' }
  ],
  linkTargetType: [
    { label: '促销活动', value: '促销活动' },
    // { label: '商品分类', value: '商品分类' },
    // { label: '优惠券', value: '优惠券' },
    // { label: '商品详情页', value: '商品详情页' },
    // { label: '品牌', value: '品牌' },
    // { label: '专题页', value: '专题页' },
    { label: 'H5页面', value: 'H5页面' }],

  yesNo: {
    'true': '有效',
    'false': '无效'
  },
  isLeader: {
    'true': '是',
    'false': '否'
  },
  isSelfDelivery: {
    'true': '是',
    'false': '否'
  },
  isPutOn: {
    '1': '是',
    '0': '否'
  },
  lockStatus: {
    '1': '正常',
    '0': '已锁定'
  },
  cityList: [
    {
      value: 'zhejiang',
      label: 'Zhejiang',
      children: [
        {
          value: 'hangzhou',
          label: 'Hangzhou',
          children: [
            {
              value: 'xihu',
              label: 'West Lake'
            }
          ]
        }
      ]
    },
    {
      value: 'jiangsu',
      label: 'Jiangsu',
      children: [
        {
          value: 'nanjing',
          label: 'Nanjing',
          children: [
            {
              value: 'zhonghuamen',
              label: 'Zhong Hua Men'
            }
          ]
        }
      ]
    }
  ]
}
const baseMixin = {
  data () {
    return { codeList }
  },
  computed: {
    ...mapState({
      // layout: state => state.app.layout,
      navTheme: state => state.app.theme,
      // primaryColor: state => state.app.color,
      colorWeak: state => state.app.weak,
      //      fixedHeader: state => state.app.fixedHeader,
      //      fixedSidebar: state => state.app.fixedSidebar,
      //   contentWidth: state => state.app.contentWidth,
      //    autoHideHeader: state => state.app.autoHideHeader,

      // isMobile: state => state.app.isMobile,
      sideCollapsed: state => state.app.sideCollapsed,
      multiTab: state => state.app.multiTab
    }),
    isTopMenu () {
      return this.layout === 'topmenu'
    }
  },
  methods: {
    alertError (errs) {
      let errors = []
      for (let key in errs) {
        if (errs[key]['errors']) {
          errs[key]['errors'].forEach(error => {
            errors.push(error.message)
          })
        } else {
          for (let key2 in errs[key]) {
            if (errs[key][key2]['errors']) {
              errs[key][key2]['errors'].forEach(error => {
                errors.push(error.message)
              })
            }
          }
        }
      }
      this.$message.error(errors.join(', '))
    },
    /**
     * 限定输入为两位小数
     * @param event
     * @param value
     * @param data
     * @param fieldName
     * @returns {string}
     */
    formatMoneyInput (event, value, data, fieldName) {
      let result = (event.target.value + '').replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
      // console.debug('formatMoney', event, value, result)
      data[fieldName] = result
      this.$forceUpdate()
      return result
    },
    isSideMenu () {
      return !this.isTopMenu
    },
    /**
     * @description 格式化金额 bug
     * @param number：要格式化的数字
     * @param decimals：保留几位小数 默认0位
     * @param decPoint：小数点符号 默认.
     * @param thousandsSep：千分位符号 默认为,
     */
    formatMoney (number, decimals = 0, decPoint = '.', thousandsSep = ',') {
      number = (number + '').replace(/[^0-9+-Ee.]/g, '')
      const n = !isFinite(+number) ? 0 : +number
      const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
      const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
      const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
      let s = ''
      const toFixedFix = function (n, prec) {
        const k = Math.pow(10, prec)
        // console.log('math =>',n, k, (n * k) / k)
        return '' + Math.round((n * k)) / k
      }
      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
      const re = /(-?\d+)(\d{3})/
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, '$1' + sep + '$2')
      }
      // console.log('dec =>', s, dec)
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
      }
      // console.log('s =>', s)
      return s.join(dec)
    },

    /**
     * 格式化金额（区分币种）
     * @param number 金额数值
     * @param type 币种
     * @param multiple 倍数
     */
    formatTypeMoney (number, type, multiple) {
      // 默认金额为正值
      let zs = true
      if (number < 0) {
        zs = false
        number = -number
      }

      if (!multiple || multiple === 0) {
        multiple = 1
      }
      let decm = 0
      let init = 1
      while (multiple > init) {
        init = init * 10
        decm = decm + 1
      }

      number = (number / multiple).toFixed(decm)
      // console.log("number fixed",number)
      const money = this.formatMoney(number, decm, '.', ',')
      // console.log("number formatMoney",number)
      if (type === '美国美元') {
        return zs ? ('USD $' + money) : ('USD -$' + money)
      } else if (type === '墨西哥比索') {
        return zs ? ('MEX $' + money) : ('MEX -$' + money)
      } else if (type === '巴西雷亚尔') {
        return zs ? ('BRL $' + money) : ('BRL -$' + money)
      } else if (type === '阿根廷比索') {
        return zs ? ('ARS $' + money) : ('ARS -$' + money)
      }
    },

    /**
     * 格式化数字
     * @param number
     * @param decimals
     */
    formatDecimals (number, decimals = 2) {
      number = +number
      if (number === 0 || number === '') {
        return 0
      }
      return this.formatMoney(number, decimals, '.', '')
    },
    /**
     * 格式化数字
     * @param number
     * @param decimals
     */
    formatCount (number) {
      number = +number
      if (number === 0 || number === '') {
        return 0
      }
      return this.formatMoney(number, 0, '.', '')
    },
    formatDate (value) {
      if (value.indexOf('-') > -1) {
        if (value.length >= 10) {
          value = value.substr(0, 10)
        }
      } else if (typeof value === 'number') {
        value = moment(value).format('YYYY-MM-DD')
      }
      return value
    },
    formatTime (value) {
      if (value.indexOf('-') > -1) {
        if (value.length >= 10) {
          value = value.substr(0, 10)
        }
      } else if (typeof value === 'number') {
        value = moment(value).format('HH:mm')
      }
      return value
    },
    formatDateTime (value) {
      // console.debug('formatDateTime', value)
      if ((!typeof value === 'number') && value.indexOf('-') > -1) {
        if (value.length >= 10) {
          value = value.substr(0, 10)
        }
        // console.debug('formatDateTime1', value)
      } else if (typeof value === 'number') {
        value = moment(value).format('YYYY-MM-DD HH:mm:ss')
        // console.debug('formatDateTime3', value)
      }
      return value
    },
    getCode (name, key) {
      if (codeList[name] && codeList[name][key + '']) {
        return codeList[name][key + '']
      }
      return key
    },
    getSelectOptions (name) {
      let result = []
      const data = codeList[name]
      if (data) {
        if (Array.isArray(data)) {
          result = data
        } else {
          result = Object.keys(data).map(value => ({ label: data[value], value }))
        }
      }
      return result
    },
    bindToGlobal (obj, key = 'var') {
      window[key] = {}
      for (const i in obj) {
        window[key][i] = obj[i]
      }
    }
  }
}

export {
  baseMixin
}
